@media only screen and (max-width: 900px) {
  .content {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
.content {
  padding: 10px 10px;
  padding-left: 255px;
  font-weight: 300 !important;
}
body {
  margin: 0;
  font-family: "Kanit", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2,
h3,
h6,
div,
a,
th {
  font-family: "Kanit" !important;
  font-weight: 300;
}

select,
option,
input,
td,
textarea,
label {
  font-family: "Kanit" !important;
  font-weight: 300 !important;
}

.td {
  padding: 5px 5px !important;
}
.controlTd {
  padding: 2px 2px !important;
}

.kanit {
  font-family: "Kanit" !important;
  font-weight: 300 !important;
}

.bg_green {
  background: #00a152 !important;
  color: #fff !important;
}

.bg_orange {
  background: #ef6c00 !important;
  color: #fff !important;
}

.error-text-field {
  background: #fdd !important;
}

.alignCenter {
  text-align: center !important;
}

.bold {
  font-weight: 400 !important;
}
.input_small {
  padding: 11px 11px !important;
}
