@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@200;300;400&display=swap");
.sarabun {
  font-family: "Sarabun" !important;
}

.bold {
  font-weight: 400 !important;
}
.center {
  text-align: center !important;
}
.div-line {
  margin-top: 0.05cm;
}
